@font-face {
  font-family: 'HK Guise';
  src: local('HK Guise'), url(./styles/HKGuise-Regular.woff) format('woff');
  font-weight: 400;
}

.d-flex {
  display: flex;
}
.text-white {
  color: #dfdfdf;
}
.jc-center {
  justify-content: center;
}
.ai-center {
  align-items: center;
}

html {
  background-color: #121212;
}

#root {
  height: 100vh;
  width: 100vw;
  background-color: #121212;
}
.App {
  height: 100vh;
  background-color: #121212;
  text-align: center;
  min-width: 240px;
  max-width: 1280px;
  margin: auto;

  color: #dfdfdf;
  font-family: 'HK Guise', sans-serif;
}

h1 {
  margin: 0;
  font-size: 32pt;
}

header {
  padding: 20px 0px 20px 0px;
}

header a {
  color: inherit;
  text-decoration: inherit;
}

header img,
header .icon {
  height: 34px;
  width: 34px;
}

header .type {
  height: 16px;
}

.standalone_number_metric {
  font-size: 72;
  margin-top: 12px;
  line-break: normal;
}
