.card_container {
  max-width: 720px;
  min-width: 336px;
  margin-top: 24px;
  margin-bottom: 24px;
  margin: 24px auto;
  padding-bottom: 32px;
}

.small_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  background-color: #212121;
  border-radius: 8px;
  margin: 12px 0px;
  padding: 24px;
  min-width: 300px;
  min-height: 200px;
  border: 1px solid black;
}

.card_small {
  max-width: 300px;
  min-width: 300px;
}
