.lds_ring {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}

.lds_ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 6px solid #F35E0A;
  border-radius: 50%;
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #F35E0A transparent transparent transparent;
}

.lds_ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds_ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds_ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}